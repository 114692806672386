<template>
  <div>
    <el-popover
        ref="popover"
        :visible-arrow="false"
        placement="bottom"
        popper-class="classMainPopperClass"
        width="240"
        trigger="hover">
      <div class="classMainBox">
        <div v-for="(classItem, classIndex) in lists"
             v-show="classIndex < 11"
             :key="'class' + classIndex"
             class="goodsClassMain">
          <div
              class="goodsCl"
              @click="commoditySearchPageJump(classItem)"
          >
            <div class="classImg">
              <img :src="classItem.icon_default" alt=""/>
              <img :src="classItem.icon_change" alt=""/>
            </div>
            <div class="classSpan">
                <span>{{
                    !$store.state.showChinese
                        ? classItem.translate_name
                        : classItem.chinese_name
                  }}</span>
            </div>
          </div>
          <div style="width: 12px;background: transparent;height: 100%"></div>
          <div class="classChildBox">
            <div class="classChild">
              <div v-for="(childsItem, childsIndex) in classItem.childs" :key="childsIndex">
                <div class="className">
                  {{
                    !$store.state.showChinese
                        ? childsItem.translate_name
                        : childsItem.chinese_name
                  }}
                </div>
                <a v-for="(childssItem, childssIndex) in childsItem.childs"
                   :key="childssIndex"
                   href="#"
                   @click="commoditySearchPageJump(childssItem)">{{
                    !$store.state.showChinese
                        ? childssItem.translate_name
                        : childssItem.chinese_name
                  }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="reference" class="allClass">
        <svg
            id="Capa_1"
            class="svg"
            viewBox="0 0 544 545.3"
            x="0px"
            xml:space="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            y="0px"
        >
            <g>
              <path
                  d="M218.3,245.9H58.6C43.9,245.9,32,234,32,219.3V59.7C32,45,43.9,33.1,58.6,33.1h159.6c14.7,0,26.6,11.9,26.6,26.6v159.6  C244.9,234,232.9,245.9,218.3,245.9z M58.6,59.7v159.6h159.6V59.7L58.6,59.7z M58.6,46.4l0,13.3h0V46.4z"
              ></path>
            </g>
          <g>
              <path
                  d="M536.2,158.3L423.3,271.2c-10.4,10.4-27.3,10.4-37.6,0L272.8,158.3c-10.4-10.4-10.4-27.2,0-37.6L385.7,7.8  c10.4-10.4,27.3-10.4,37.6,0l112.9,112.9C546.6,131,546.6,147.9,536.2,158.3L536.2,158.3z M291.6,139.5l112.9,112.9l112.9-112.9  L404.5,26.6L291.6,139.5z M282.2,130.1l9.4,9.4l0,0L282.2,130.1z"
              ></path>
            </g>
          <g>
              <path
                  d="M484.3,512H324.7c-14.7,0-26.6-11.9-26.6-26.6V325.7c0-14.7,11.9-26.6,26.6-26.6h159.6c14.7,0,26.6,11.9,26.6,26.6v159.6  C510.9,500.1,499,512,484.3,512z M324.7,325.7v159.6h159.6V325.7L324.7,325.7z M324.7,312.4l0,13.3h0V312.4z"
              ></path>
            </g>
          <g>
              <path
                  d="M218.3,512H58.6C43.9,512,32,500.1,32,485.4V325.7c0-14.7,11.9-26.6,26.6-26.6h159.6c14.7,0,26.6,11.9,26.6,26.6v159.6  C244.9,500.1,232.9,512,218.3,512z M58.6,325.7v159.6h159.6V325.7L58.6,325.7z M58.6,312.4l0,13.3h0V312.4z"
              ></path>
            </g>
          </svg>
        <span>カテゴリー</span>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lists: [],
    }
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$api.getShopGroup(this.form).then((result) => {
        this.lists = result.data;
      })
    },
    commoditySearchPageJump(item) {
      if (this.$fun.isOfficialWeb()) {
        __bl.sum('分类关键词搜索数量统计');
      }
      this.$store.commit("getsearchInfo", item.translate_name);
      this.$fun.routerToPage('/CommoditySearch?keyword=' + item.translate_name + '&classifyKeyword=true')
    },
  }
}
</script>
<style lang="scss" scoped>
.allClass {
  width: 240px;
  height: 50px;
  background: #B4272B;
  padding-left: 15px;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
    margin-left: 9px;
  }

  svg {
    width: 20px;
    fill: #fff !important;
    color: #fff;
  }
}

.classMainBox {
  background-color: #fff;
  min-height: 615px;
  display: flex;
  height: 615px;
  flex-direction: column;
  border-radius: 6px;
  padding: 12px 0 12px 12px;

  .goodsClassMain {
    display: flex;
    flex: 1;
    cursor: pointer;
    background-color: #fff;

    .goodsCl {
      width: 100%;
      display: flex;
      padding-left: 20px;
      padding-right: 25px;

      .classImg {
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          color: white;
        }

        svg {
          width: 22px;
          height: 22px;
          margin-right: 15px;
        }

        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;

          &:last-child {
            display: none;
          }
        }
      }

      .classSpan {
        display: flex;
        align-items: center;
        width: 100%;

        span {
          font-size: 13px;
          font-weight: 500;
          line-height: 1.9em;
        }

      }
    }

    .classChildBox {
      position: absolute;
      left: 100%;
      display: none;
      z-index: 3;
      top: -1px;
      overflow: hidden;
      height: 617px;
      padding-bottom: 50px;

      .shadowBlock {
        // border: solid;
        height: 100%;
      }

      .classChild {
        > div {
          max-height: 100%;
        }

        padding: 0 0 20px 20px;
        margin-left: -20px;
        height: 628px;
        overflow-y: auto;

        &::-webkit-scrollbar {
          /*滚动条整体样式*/
          width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
          height: 1px;
        }

        &::-webkit-scrollbar-thumb {
          /*滚动条里面小方块*/
          border-radius: 10px;
          -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          background: #c0c0c0;
        }

        &::-webkit-scrollbar-track {
          /*滚动条里面轨道*/
          -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
          border-radius: 10px;
        }

        display: flex;
        background-color: white;

        .className {
          font-weight: 600;
          font-size: 14px;
          position: sticky;
          top: 0px;
          padding-top: 20px;
          padding-left: 30px;
          padding-bottom: 10px;
          color: #B4272B;
          background-color: white;
          width: 100%;
        }

        a {
          width: 210px;
          height: 34px;
          border-radius: 5px;
          text-overflow: -o-ellipsis-lastline;
          line-height: 14px;
          overflow: hidden;
          display: flex;
          align-items: center;
          font-size: 13px;
          font-weight: 500;
          padding-left: 30px;
          transition: 0.3s;

          &:hover {
            background-color: #FAF2F2;
          }
        }
      }
    }
  }

  .goodsClassMain:hover {
    .goodsCl {
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
      position: relative;
      z-index: 2;
      background-color: #B4272B;
      color: #fff;
    }

    .classChild {
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
      border: 1px solid #e8e8e8;
      border-radius: 6px;
      position: relative;
      left: 2px;
      z-index: 9;
    }

    .classImg {
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        display: none;

        &:last-child {
          display: block;
        }
      }
    }

    .classSpan {
      span {
        color: #fff;
      }
    }

    .classChildBox {
      display: block;
    }
  }
}
</style>